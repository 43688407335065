import React from 'react';
import linkedin from '../assets/icons8-linkedin.svg';
import instagram from '../assets/icons8-instagram.svg';
import x from '../assets/icons8-twitterx.svg';
import photo from '../assets/stant.jpg';
import HeroSection from '../components/Hero';
import photohero from '../assets/ourteam.svg';
import kıvılcım from '../assets/kıvılcım.png';
import aleyna from '../assets/Aleyna.png';
import ahmet from '../assets/Ahmet.png'
import ozan from '../assets/OzanEfeAkpinar.jpg';
import adil from '../assets/Adil.png';
import ilayda from '../assets/ilayda.png';
import elif from '../assets/Elif.png';
import berkay from '../assets/Berkay.png';
import can from '../assets/Can.png';
import esmanur from '../assets/Esmanur.png';
import faik from '../assets/Faik.png';
import gülse from '../assets/gulse.png';
import ilkhan from '../assets/ilkhan.png';
import tülay from '../assets/Tulay.png';
import zeynep from '../assets/zeynep.png';
import arda from '../assets/Arda.png';
import onur from '../assets/onur.jpg';

const Card = ({ title, content, backgroundImage }) => (
  <div className="flex bg-gradient-to-b from-[#5F2875] to-[#B14BDB] w-card aspect-[0.7921/1] min-w-[240px] rounded-custom overflow-hidden text-customColor">
    <div className="w-full h-full bg-cover bg-center bg-no-repeat shadow-inner" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="flex flex-col justify-between rounded-custom overflow-hidden h-full w-full aspect-[0.2640/1]">
        <div className="bg-white bg-opacity-90 rounded-custom overflow-hidden shadow-custom mt-auto p-1 m-1">
          <h3 className="text-lg font-medium mt-1">{title}</h3>
          <p className="text-customColor text-sm mb-1">{content}</p>
          <div className='grid grid-cols-3 justify-items-center mb-2'>
            <a href="https://www.linkedin.com" target="_blank">
              <img src={linkedin} className='icon' loading='lazy' />
            </a>
            <a href="https://www.x.com" target="_blank">
              <img src={x} className='icon' loading='lazy' />
            </a>
            <a href="https://www.instagram.com" target="_blank">
              <img src={instagram} className='icon' loading='lazy' />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const OurTeam = () => {
  return (

    <div>
      <HeroSection title="EKİBİMİZ" p="ACM Hacettepe" photo={photohero} />
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    <div>
      <p className='text-5xl md:text-8xl font-medium text-center'>Yönetim Kurulu</p>
      <br/>
      <br/>
      <br/>
      <div className="flex flex-col items-center justify-center min-h-screen p-4 -mb-6">
        <div className="grid grid-cols-1 mb-4 gap-4 justify-items-center">
          <div>
            <Card title="Adil KÜLLÜK" content="Yönetim Kurulu Başkanı" backgroundImage={adil} />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 mb-4 gap-4 justify-items-center">
          <div>
            <Card title="İlayda AK" content="Yönetim Kurulu Başkan Yardımcısı" backgroundImage={ilayda} />
          </div>
          <div>
            <Card title="Berkay BARULAY" content="Başkan Danışmanı" backgroundImage={berkay} />
          </div>
          <div>
            <Card title="Onur BAKİ" content="Yönetim Kurulu Başkan Yardımcısı" backgroundImage={onur} />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 mb-4 gap-4 justify-items-center">
          <div>
            <Card title="Tülay TURHAN" content="Eş Direktör" backgroundImage={tülay} />
          </div>
          <div>
            <Card title="Aleyna GÜNALAY" content="Eş Direktör" backgroundImage={aleyna} />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 mb-4 gap-4 justify-items-center">
          <div>
            <Card title="Gülse ATUN" content="Ar-Ge Birimleri Başkanı" backgroundImage={gülse} />
          </div>
          <div>
            <Card title="Faik Kerem GÜÇLÜ" content="Genel Sekreter" backgroundImage={faik} />
          </div>
          <div>
            <Card title="Mevlüt Can ÇETİN" content="Sayman" backgroundImage={can} />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 mb-4 gap-4 justify-items-center">
          <div>
            <Card title="Ozan Efe AKPINAR" content="Reklam ve Tanıtım Koordinatörlüğü Eş Koordinatörü" backgroundImage={ozan} />
          </div>
          <div>
            <Card title="Esmanur BALLI" content="Reklam ve Tanıtım Koordinatörlüğü Eş Koordinatörü" backgroundImage={esmanur} />
          </div>
          <div>
            <Card title="İlkhan Arda AKMACA" content="Reklam ve Tanıtım Koordinatörlüğü Eş Koordinatörü" backgroundImage={ilkhan} />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 mb-4 gap-4 justify-items-center">
          <div>
            <Card title="Zeynep ÇİFTÇİ" content="Kurumsal İlişkiler Koordinatörlüğü Eş Koordinatörü" backgroundImage={zeynep} />
          </div>
          <div>
            <Card title="Kıvılcım Nehir BOZBURUN" content="Kurumsal İlişkiler Koordinatörlüğü Eş Koordinatörü" backgroundImage={kıvılcım} />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 mb-4 gap-4 justify-items-center">
          <div>
            <Card title="Ahmet ÇELEN" content="Teknik Etkinlik Koordinatörlüğü Eş Koordinatörü" backgroundImage={ahmet} />
          </div>
          <div>
            <Card title="Kaan TANIR" content="Teknik Etkinlik Koordinatörlüğü Eş Koordinatörü" backgroundImage={photo} />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-center">
          <div>
            <Card title="Arda Samed ÖZKILIÇ" content="Sosyal Etkinlik Koordinatörlüğü Koordinatörü" backgroundImage={arda} />
          </div>
          <div>
            <Card title="Elif BADAK" content="Halkla İlişkiler Koordinatörlüğü Koordinatörü" backgroundImage={elif} />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default OurTeam;
